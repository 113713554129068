import { Replace } from "../types/utils";
import api from "./root";
import {
  Floor,
  Neighborhood,
  NewFloor,
  NewNeighborhood,
  NewTag,
  PopulatedSpaceContainer,
  SpaceContainer,
  SpaceContainerId,
  SpaceContainerType,
  Tag,
} from "./space-containers";
import type { Space, SpaceAssignment, SpaceId } from "./spaces";

export function populateHqFloors(hq: SpaceContainer<{ populateSpaces: true }>) {
  const spaceIdMapping = hq.spaces.reduce<Record<SpaceId, Space>>(
    (acc, space) => {
      acc[space._id] = space;
      return acc;
    },
    {}
  );

  return {
    ...hq,
    floors: hq.floors?.map((floor) => {
      const floorInfo = { _id: floor._id, name: floor.name };

      return {
        ...floor,
        tags: floor.tags?.map((tag) => ({
          ...tag,
          populatedSpace: spaceIdMapping[tag.space],
          floorInfo,
        })),
      };
    }),
  } as PopulatedSpaceContainer;
}

export async function getActiveCompanyOffices(options?: {
  sortByUser?: boolean;
}) {
  const response = await api.get<{
    containers: SpaceContainer<{ populateSpaces: false }>[];
  }>("/company-offices/list-active", {
    params: { sortByUser: options?.sortByUser },
  });
  return response.data.containers;
}

export async function getActiveCompanyOfficesWithPopulatedSpaces(
  signal: AbortSignal
) {
  const response = await api.get<{
    containers: SpaceContainer<{ populateSpaces: true }>[];
  }>("/company-offices/list", {
    signal,
    params: { populate_spaces: true, include_deleted_offices: false },
  });
  return response.data.containers.map(populateHqFloors);
}

export async function getActiveCompanyOfficesWithoutSpaces(
  signal: AbortSignal
) {
  const response = await api.get<{
    containers: Replace<SpaceContainer, { spaces: SpaceId[] }>[];
  }>("/company-offices/list", {
    signal,
    params: { populate_spaces: false, include_deleted_offices: false },
  });
  return response.data.containers;
}

export async function getAllCompanyOffices(signal: AbortSignal) {
  const response = await api.get<{
    containers: Replace<SpaceContainer, { spaces: SpaceId[] }>[];
  }>("/company-offices/list", {
    signal,
    params: {
      populate_spaces: false,
      include_deleted_offices: true,
    },
  });
  return response.data.containers;
}

export async function createSimpleCompanyHq(options: {
  place: google.maps.places.PlaceResult;
  hqInfo: Pick<Space, "title" | "seats"> &
    Partial<Pick<Space, "directions" | "wifiDetails">> & {
      workingDays: Day[];
      photoSrc?: string;
      maxDaysToBookInAdvance?: number | null;
    };
  childSpaces: (Pick<Space, "title" | "amenities" | "seats" | "bookingType"> & {
    photoSrc?: string;
  })[];
}) {
  const response = await api.post<{
    container: SpaceContainer;
    commonSpace: Space;
    meetingRooms: Space[];
  }>("/company-offices/create", {
    ...options,
    containerType: SpaceContainerType.HqWithoutFloorplan,
  });
  return response.data;
}

export async function updateSimpleCompanyHq(options: {
  hqInfo: Pick<SpaceContainer, "_id"> &
    Partial<
      Pick<Space, "title" | "seats" | "directions" | "wifiDetails" | "contacts">
    > & {
      workingDays?: Day[];
      photoSrc?: string;
      maxDaysToBookInAdvance?: number | null;
    };
  childSpaces: (Partial<
    Pick<Space, "_id" | "title" | "amenities" | "seats" | "bookingType">
  > & {
    photoSrc?: string;
  })[];
}) {
  const response = await api.patch<{
    container: SpaceContainer;
    commonSpace: Space;
    meetingRooms: Space[];
  }>("/company-offices/update", { ...options, hqId: options.hqInfo._id });
  return response.data;
}

type HQInfoCommonFields = Pick<
  SpaceContainer,
  "title" | "workingHours" | "amenities" | "wifiDetails" | "contacts"
> &
  Partial<Pick<SpaceContainer, "directions">> & {
    checkinRequiredSettings: SpaceContainer["bookingSettings"]["general"]["checkinRequired"];
  };

export async function createCompanyHq(options: {
  place: google.maps.places.PlaceResult;
  hqInfo: HQInfoCommonFields & {
    floors: NewFloor[];
    photoSrc?: string;
    maxDaysToBookInAdvance?: number | null;
  };
}) {
  const response = await api.post<{ container: SpaceContainer }>(
    "/company-offices/create",
    { ...options, containerType: SpaceContainerType.HqWithFloorplan }
  );
  return response.data;
}

export async function updateCompanyHq(
  hqInfo: Pick<SpaceContainer, "_id"> &
    Partial<HQInfoCommonFields> & {
      floors?: (
        | Pick<Floor, "_id" | "name" | "uid">
        | Pick<NewFloor, "name" | "uid">
      )[];
      photoSrc?: string;
      maxDaysToBookInAdvance?: number | null;
    }
) {
  const response = await api.patch<{ container: SpaceContainer }>(
    "/company-offices/update",
    { ...hqInfo, hqId: hqInfo._id }
  );
  return response.data;
}

export async function uploadFloorplan(
  file: File,
  params: {
    containerId: string;
    floorId: string;
  }
) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("containerId", params.containerId);
  formData.append("floorId", params.floorId);

  const response = await api.post<{ container: SpaceContainer }>(
    "/company-offices/floorplan",
    formData
  );
  return response.data;
}

export async function createTags(params: {
  hqId: SpaceContainerId;
  floorId: string;
  commonValues: Partial<NewTag>;
  tags: Partial<NewTag>[];
  spaceInfo: {
    title: string;
    seats: number;
    photoSrc?: string;
    amenities: string[];
    assignments: SpaceAssignment[] | null;
    description: string;
    bookingSettings?: Space["bookingSettings"];
  };
}) {
  const response = await api.post<{ container: SpaceContainer; tags: Tag[] }>(
    "/company-offices/tag",
    params
  );
  return response.data;
}

export async function updateTags(params: {
  hqId: SpaceContainerId;
  floorId: string;
  commonValues?: Partial<Tag>;
  tags: Partial<Tag>[];
  spaceInfo?: {
    title?: string;
    seats?: number;
    photoSrc?: string;
    amenities?: string[];
    assignments?: SpaceAssignment[] | null;
    description?: string;
    bookingSettings?: Space["bookingSettings"];
  };
}) {
  const response = await api.patch<{ container: SpaceContainer }>(
    "/company-offices/tag",
    params
  );
  return response.data;
}

export const createNeighborhood = async (params: {
  hqId: SpaceContainerId;
  floorId: string;
  neighborhood: NewNeighborhood;
}) => {
  const response = await api.post<{ container: SpaceContainer }>(
    "/company-offices/neighborhood",
    params
  );
  return response.data;
};

export const updateNeighborhood = async (params: {
  hqId: SpaceContainerId;
  floorId: string;
  neighborhood: Neighborhood;
}) => {
  const response = await api.put<{ container: SpaceContainer }>(
    "/company-offices/neighborhood",
    params
  );
  return response.data;
};

export const deleteNeighborhood = async (params: {
  hqId: SpaceContainerId;
  floorId: string;
  neighborhoodId: string;
}) => {
  const response = await api.delete<{ container: SpaceContainer }>(
    "/company-offices/neighborhood",
    { data: params }
  );
  return response.data;
};

export async function deleteChildSpaces(params: {
  hqId: string;
  spaceIds: SpaceId[];
}) {
  const response = await api.delete("/company-offices/space", { params });
  return response.data;
}

export async function publishHq(params: { hqId: SpaceContainerId }) {
  const response = await api.post<{ container: SpaceContainer }>(
    "/company-offices/publish",
    params
  );
  return response.data;
}
