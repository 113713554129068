import { SpaceType } from "src/constants";
import { IPhoto } from "src/types/photo-data-types";
import { Replace } from "src/types/utils";
import { IntegrationSource } from "./common";
import api from "./root";
import type {
  Space,
  SpaceBase,
  SpaceBasePopulations,
  SpaceBookingType,
  SpaceGeo,
  SpaceId,
  SpacePopulations,
  SpaceWithPrices,
} from "./spaces";

export enum SpaceContainerType {
  HqWithFloorplan = "HqWithFloorplan",
  HqWithoutFloorplan = "HqWithoutFloorplan",
  Regular = "Regular",
}

export type TagBookingType = Exclude<
  SpaceBookingType,
  SpaceBookingType.EventSpace
>;

export type Tag = {
  pos: { x: `${number}%`; y: `${number}%` };
  bookingType: TagBookingType;
  space: SpaceId;
  _id: string;
};

export type PopulatedTag<TSpace extends Space = Space> = Tag & {
  populatedSpace: TSpace;
  floorInfo: Pick<Floor, "_id" | "name">;
};

export type NewTag = Omit<Tag, "_id" | "space" | "populatedSpace"> & {
  uid: string;
};

export type Neighborhood = {
  _id: string;
  name: string;
  color: string;
  shape: {
    type: "rectangle";
    x: string;
    y: string;
    width: string;
    height: string;
  };
};

export type NeighboroodCoords = Replace<
  Neighborhood,
  { shape: { x: number; y: number; width: number; height: number } }
>;

export type NewNeighborhood = Omit<Neighborhood, "_id">;

export type Floor = {
  _id: string;
  uid: string;
  name: string;
  floorplan?: {
    file?: File;
    url?: string;
  };
  tags: Tag[];
  neighborhoods: Neighborhood[];
  tagSize?: "small" | "medium" | "large";
};

export type PopulatedFloor<TSpace extends Space = Space> = Replace<
  Floor,
  { tags: PopulatedTag<TSpace>[] }
>;

export type NewFloor = Omit<Floor, "_id" | "tags" | "neighborhoods">;

export type SpaceContainerId = string & { readonly _opaque: unique symbol };

export interface SpaceContainer<
  TPopulations extends SpaceBasePopulations & {
    populateHostOnSpaces?: boolean;
    populateSpaces?: boolean;
  } = {},
  TSpacePopulations extends SpacePopulations & {
    populateHost?: TPopulations["populateHostOnSpaces"];
    populateSpaceContainer: false;
  } = {
    populateHost: TPopulations["populateHostOnSpaces"];
    populateSpaceContainer: false;
  }
> extends SpaceGeo,
    SpaceBase<{ populateHost: TPopulations["populateHost"] }> {
  _id: SpaceContainerId;
  containerType: SpaceContainerType;
  spaces?: TPopulations["populateSpaces"] extends true
    ? Space<TSpacePopulations>[]
    : TPopulations["populateSpaces"] extends false
    ? SpaceId[]
    : SpaceId[] | Space<TSpacePopulations>[];
  floors: Floor[];
  limits?: {
    default?: {
      maxDaysToBookInAdvance?: number | null;
    };
  };
  bookingSettings?: {
    general?: {
      checkinRequired?: {
        enabled?: boolean;
        autoCancellationMinutesIntoTheBooking?: number;
      };
    };
  };
}

export type SpaceContainerWithSpaces = Replace<
  SpaceContainer,
  { spaces: SpaceWithPrices[] }
>;

export type PopulatedSpaceContainer<
  TContainer extends SpaceContainerWithSpaces = SpaceContainerWithSpaces
> = Replace<
  TContainer,
  { floors: PopulatedFloor<TContainer["spaces"][number]>[] }
>;

export function isNewTag(tag: Tag | NewTag): tag is NewTag {
  return !(tag && "_id" in tag && tag._id);
}

export const isNewNeighborhood = (
  neighborhood: Neighborhood | NewNeighborhood
): neighborhood is NewNeighborhood => {
  return !(neighborhood && "_id" in neighborhood && neighborhood._id);
};

export const isExistingNeighborhood = (
  neighborhood: Neighborhood | NewNeighborhood
): neighborhood is Neighborhood => {
  return !isNewNeighborhood(neighborhood);
};

const getById = async ({ containerId }: { containerId: string }) => {
  const response = await api.get<
    SpaceContainer<{
      populateHost: false;
      populateHostOnSpaces: false;
      populateSpaces: true;
    }>
  >(`/host/containers/${containerId}`);

  return response.data;
};

const deleteById = async ({
  containerId,
}: {
  containerId: SpaceContainer["_id"];
}) => {
  const response = await api.delete<unknown>(`/host/containers/${containerId}`);

  return response.data;
};

const addSpaces = async ({
  containerId,
  options,
}: {
  containerId: string;
  options: {
    type?: SpaceType;
    spacesToCreate: {
      bookingType: SpaceBookingType;
      count: number;
    }[];
  };
}) => {
  const response = await api.post<{ spaces: Space[] }>(
    `/host/containers/add-spaces/${containerId}`,
    options
  );
  return response.data;
};

type CreateOrUpdateLocationRequest = Pick<
  SpaceGeo,
  | "city"
  | "street1"
  | "street2"
  | "postal"
  | "state"
  | "googlePlaceId"
  | "country"
  | "location"
> & {
  forceLocationOverride?: boolean;
};

const addLocation = async (options: CreateOrUpdateLocationRequest) => {
  const response = await api.post<SpaceContainer>(`/host/containers`, options);
  return response.data;
};

const updateLocation = async ({
  containerId,
  options,
}: {
  containerId: SpaceContainerId;
  options: CreateOrUpdateLocationRequest;
}) => {
  const response = await api.post<{ spaces: Space[] }>(
    `/host/containers/update-location/${containerId}`,
    options
  );
  return response.data;
};

export type PatchContainerBody = Partial<
  Replace<SpaceContainer, { spaces: (Partial<Space> & Pick<Space, "_id">)[] }>
>;
const update = async ({
  containerId,
  container,
}: {
  containerId: string;
  container: PatchContainerBody;
}) => {
  const response = await api.patch<Partial<SpaceContainer>>(
    `/host/containers/${containerId}`,
    container
  );
  return response.data;
};

const uploadPhoto = async (id: string, photo: Partial<IPhoto>, file) => {
  const formData = new FormData();
  const { isCoverImage, ...uploadData } = photo;
  formData.append("space-pic", file);
  formData.append("containerId", id);
  Object.entries(uploadData).forEach(([key, value]) => {
    formData.append(key, String(value));
  });

  const response = await api.post<{ id: string; photo: IPhoto }>(
    "/photos/container",
    formData
  );
  return response.data;
};

const deletePhoto = async (id: string, photo: Pick<IPhoto, "src">) => {
  const response = await api.delete<Partial<SpaceContainer>>(
    `/host/containers/${id}/photo`,
    { data: { photo } }
  );
  return response.data;
};

const updatePhotoMetadata = async (id: string, photo: Pick<IPhoto, "src">) => {
  const response = await api.patch<Partial<SpaceContainer>>(
    `/host/containers/${id}/photo`,
    { photo }
  );
  return response.data;
};

const connectIntegration = async ({
  id,
  resourceIdentifier,
  source,
}: {
  id: SpaceContainerId;
  resourceIdentifier: Record<string, unknown>;
  source: IntegrationSource;
}) => {
  const { data } = await api.post<SpaceContainer>(
    `/host/containers/${id}/integration`,
    { identifier: resourceIdentifier, source }
  );
  return data;
};

const disconnectIntegration = async ({ id }: { id: SpaceContainerId }) => {
  const { data } = await api.delete<SpaceContainer>(
    `/host/containers/${id}/integration`
  );
  return data;
};

export const spaceContainerApi = {
  disconnectIntegration,
  connectIntegration,
  updatePhotoMetadata,
  deletePhoto,
  uploadPhoto,
  update,
  addSpaces,
  deleteById,
  getById,
  updateLocation,
  addLocation,
} as const;
