import { BikeRack } from "src/components/common/icon/amenities/bike-rack";
import { Kitchen } from "src/components/common/icon/amenities/kitchen";
import { PhoneCallArea } from "src/components/common/icon/amenities/phone-call-area";
import { Printer } from "src/components/common/icon/amenities/printer";
import { WiFi } from "src/components/common/icon/amenities/wifi";
import { IconProps } from "src/types/icon";
import { unsafeTypedKeys } from "./types/utils";

export type Amenity = {
  name: string;
  label?: string;
} & (
  | { icon: string; iconComponent?: never }
  | { iconComponent: React.FC<IconProps>; icon?: never }
);

export const Amenities = {
  WiFi: { name: "WiFi", iconComponent: WiFi },
  Restroom: { name: "Restroom", icon: "restroom" },
  ["Coffee/Tea"]: {
    name: "Coffee/Tea",
    icon: "coffee-tea",
  },
  ["Air Conditioning"]: {
    name: "Air Conditioning",
    icon: "air-conditioning",
    label: "AC",
  },
  Microwave: { name: "Microwave", icon: "microwave" },
  Kitchen: {
    name: "Kitchen",
    iconComponent: Kitchen,
  },
  ["Refrigerator space"]: {
    name: "Refrigerator space",
    icon: "refrigerator",
  },
  ["HDMI Cord"]: {
    name: "HDMI Cord",
    icon: "hdmi-cord",
  },
  Printer: { name: "Printer", iconComponent: Printer },
  ["Meeting rooms"]: {
    name: "Meeting rooms",
    icon: "meeting-room",
  },
  Whiteboard: {
    name: "Whiteboard",
    label: "Whiteboard & accessories",
    icon: "whiteboard",
  },
  ["Projector screen/TV"]: {
    name: "Projector screen/TV",
    icon: "projector",
  },
  ["Casting capabilities"]: {
    name: "Casting capabilities",
    icon: "casting-capabilities",
  },
  ["VoIP Phone"]: {
    name: "VoIP Phone",
    icon: "voip-phone",
  },
  ["Podcast equipment"]: {
    name: "Podcast equipment",
    icon: "podcast",
  },
  ["Water carafe"]: {
    name: "Water carafe",
    icon: "carafe",
  },
  ["Window view"]: {
    name: "Window view",
    icon: "window",
  },
  Accessibility: {
    name: "Accessibility",
    icon: "accessibility",
  },
  ["Outdoor seating"]: {
    name: "Outdoor seating",
    icon: "outdoor-seating",
  },
  ["Phone call area"]: {
    name: "Phone call area",
    iconComponent: PhoneCallArea,
  },
  ["Lactation room"]: {
    name: "Lactation room",
    icon: "lactation-room",
  },
  ["Paid parking on premises"]: {
    name: "Paid parking on premises",
    icon: "paid-parking",
  },
  ["Free parking"]: {
    name: "Free parking",
    icon: "free-parking",
  },
  ["On-site restaurant / cafe"]: {
    name: "On-site restaurant / cafe",
    icon: "restaurant",
  },
  ["Near public transportation"]: {
    name: "Near public transportation",
    icon: "public-transportation",
  },
  ["Bike rack"]: {
    name: "Bike rack",
    iconComponent: BikeRack,
  },
  ["Secure lockers"]: {
    name: "Secure lockers",
    icon: "lock",
  },
  ["Receptionist on-site"]: {
    name: "Receptionist on-site",
    icon: "receptionist",
  },
  ["Phone booths"]: {
    name: "Phone booths",
    icon: "phone-booths",
  },
  Rooftop: {
    name: "Rooftop",
    icon: "rooftop",
  },
  ["Catering on-site"]: {
    name: "Catering on-site",
    icon: "catering",
  },
  ["Showers on-site"]: {
    name: "Showers on-site",
    icon: "shower",
  },
  ["Monitors available"]: {
    name: "Monitors available",
    icon: "monitors",
  },
  ["Ergonomic chairs"]: {
    name: "Ergonomic chairs",
    icon: "ergonomic-chairs",
  },
  ["Fully furnished"]: {
    name: "Fully furnished",
    icon: "furnished",
  },
  ["Ethernet ports"]: {
    name: "Ethernet ports",
    icon: "ethernet",
  },
  ["Additional IT service available"]: {
    name: "Additional IT service available",
    icon: "it-service",
  },
  ["Lockable office"]: {
    name: "Lockable office",
    icon: "lockable-office",
  },
  ["Mail services available"]: {
    name: "Mail services available",
    icon: "ergonomic-chairs",
  },
  ["24/7 access"]: {
    name: "24/7 access",
    icon: "24-7-access",
  },
  ["Monitor"]: {
    name: "Monitor",
    icon: "monitor",
  },
  ["Dual Monitors"]: {
    name: "Dual Monitors",
    icon: "dual-monitors",
  },
  ["Standing desk"]: {
    name: "Standing desk",
    icon: "standing-desk",
  },
  ["Keyboard"]: {
    name: "Keyboard",
    icon: "keyboard",
  },
  ["Mouse"]: {
    name: "Mouse",
    icon: "mouse",
  },
  ["Laptop stand"]: {
    name: "Laptop stand",
    icon: "laptop-stand",
  },
  ["Zoom room"]: {
    name: "Zoom room",
    icon: "zoom-room",
  },
  ["Gender neutral restroom"]: {
    name: "Gender neutral restroom",
    icon: "gender-neutral-restroom",
  },
  ["Flip chart"]: {
    name: "Flip chart",
    icon: "flip-chart",
  },
  ["Temperature control"]: {
    name: "Temperature control",
    icon: "temperature-control",
  },
} as const satisfies Record<string, Amenity>;

export const FlexMeetingRoomAmenities = [
  Amenities.Whiteboard,
  Amenities["HDMI Cord"],
  Amenities["Projector screen/TV"],
  Amenities["Casting capabilities"],
  Amenities["VoIP Phone"],
  Amenities["Podcast equipment"],
  Amenities["Water carafe"],
  Amenities["Window view"],
  Amenities["Zoom room"],
  Amenities["Ethernet ports"],
  Amenities["Flip chart"],
] as const;

export const HqPrivateOfficeAmenities = [
  Amenities.Whiteboard,
  Amenities["Window view"],
  Amenities["Ergonomic chairs"],
  Amenities["Fully furnished"],
  Amenities["Ethernet ports"],
  Amenities.Monitor,
  Amenities["Dual Monitors"],
  Amenities["Standing desk"],
  Amenities.Keyboard,
  Amenities.Mouse,
  Amenities["Laptop stand"],
  Amenities["Temperature control"],
  Amenities["VoIP Phone"],
] as const;

export const FlexPrivateOfficeAmenities = [
  ...HqPrivateOfficeAmenities,
  Amenities["24/7 access"],
  Amenities["Mail services available"],
  Amenities["Lockable office"],
  Amenities["Additional IT service available"],
] as const;

export const FlexCommonSpaceAmenities = [
  Amenities.WiFi,
  Amenities.Restroom,
  Amenities["Coffee/Tea"],
  Amenities["Air Conditioning"],
  Amenities.Microwave,
  Amenities.Kitchen,
  Amenities["Refrigerator space"],
  Amenities.Printer,
  Amenities["Meeting rooms"],

  Amenities.Whiteboard,
  Amenities.Accessibility,
  Amenities["Outdoor seating"],
  Amenities["Phone call area"],
  Amenities["Receptionist on-site"],
  Amenities["Phone booths"],

  Amenities["Paid parking on premises"],
  Amenities["Free parking"],
  Amenities["On-site restaurant / cafe"],
  Amenities["Near public transportation"],
  Amenities["Bike rack"],

  Amenities["Lactation room"],
  Amenities["Secure lockers"],
  Amenities.Rooftop,
  Amenities["Catering on-site"],
  Amenities["Showers on-site"],
  Amenities["Monitors available"],
  Amenities["Gender neutral restroom"],
] as const;

export const EventSpaceAmenities = [
  Amenities["Catering on-site"],
  Amenities["Projector screen/TV"],
  Amenities["Monitor"],
  Amenities["HDMI Cord"],
  Amenities["Casting capabilities"],
  Amenities["Additional IT service available"],
  Amenities["Window view"],
  // TODO: OMER add missing
];

export const HqDeskAmenities = [
  Amenities.Monitor,
  Amenities["Dual Monitors"],
  Amenities["Standing desk"],
  Amenities.Keyboard,
  Amenities.Mouse,
  Amenities["Laptop stand"],
] as const;

export const HqMeetingRoomAmenities = FlexMeetingRoomAmenities;

export const AmenityNames = Object.values(Amenities).map((a) => a.name);

/*
 ** Unused function to test the correctness of the Amenities object
 */
function testAmenityNames() {
  const names: (typeof Amenities)[keyof typeof Amenities]["name"][] =
    AmenityNames;
  const names2: (typeof Amenities)[keyof typeof Amenities]["name"][] =
    unsafeTypedKeys(Amenities);
}

export const Interests = {
  Consulting: "Consulting",
  Painting: "Painting",
  Swimming: "Swimming",
  Cooking: "Cooking",
  Entrepreneurship: "Entrepreneurship",
  Photography: "Photography",
  Traveling: "Traveling",
  Art: "Art",
  Cycling: "Cycling",
  Football: "Football",
  Pilates: "Pilates",
  Writing: "Writing",
  Baseball: "Baseball",
  Dancing: "Dancing",
  Gaming: "Gaming",
  Product: "Product",
  Yoga: "Yoga",
  Basketball: "Basketball",
  Design: "Design",
  VC: "VC",
  Reading: "Reading",
  Blogging: "Blogging",
  Engineering: "Engineering",
  Marketing: "Marketing",
  Remote: "Remote",
  Cats: "Cats",
  Dogs: "Dogs",
  Mobile: "Mobile",
  Running: "Running",
  Music: "Music",
  Sales: "Sales",
} as const;

export enum SpaceType {
  Hotel = "Hotel",
  CoworkingSpace = "Coworking space",
}

export const ALL_SPACE_TYPES = Object.values(SpaceType);

export enum SpaceOnboardingStatus {
  Draft = "DRAFT",
  InReview = "IN REVIEW",
  Active = "ACTIVE",
  Deleted = "DELETED",
  Unlisted = "UNLISTED",
}

export const SpaceOnboardingStatusLabels = {
  [SpaceOnboardingStatus.Active]: "Listed",
  [SpaceOnboardingStatus.Unlisted]: "Unlisted",
  [SpaceOnboardingStatus.InReview]: "In review",
  [SpaceOnboardingStatus.Draft]: "Draft",
} satisfies Record<
  Exclude<SpaceOnboardingStatus, SpaceOnboardingStatus.Deleted>,
  string
>;

export const CommonInterests = {
  Entrepreneurship: "Entrepreneurship",
  Engineering: "Engineering",
  Technology: "Technology",
  Mobile: "Mobile",
  Product: "Product",
  Design: "Design",
  Marketing: "Marketing",
  Remote: "Remote",
  Running: "Running",
  Yoga: "Yoga",
  Cooking: "Cooking",
  Photography: "Photography",
  Painting: "Painting",
  Gaming: "Gaming",
  Traveling: "Traveling",
  Dogs: "Dogs",
  Cats: "Cats",
  Reading: "Reading",
  Blogging: "Blogging",
} as const;

export const WorkStyles = {
  Social: "Social",
  Headphones: "Headphones",
  Focused: "Focused",
  EarlyBird: "Early Bird",
  NightOwl: "Night Owl",
  LunchBreaks: "Lunch Breaks",
  CoffeeChat: "Coffee Chat",
} as const;

export const Objectives = {
  Networking: "Networking",
  WorkWithColleagues: "Work with Colleagues",
  IncreaseProductivity: "Increase Productivity",
  ChangeOfScenery: "Change of Scenery",
} as const;

export const BookingReasons = {
  IncreaseProductivity: "Increase productivity",
  ChangeOfScenery: "Change of scenery",
  WorkWithColleagues: "Work with colleagues",
  MeetingWithCustomers: "Meeting with customers",
};

export const OtherBookingReasonLabel = "Other";

export const CompanyOnboardingObjectives = {
  RemoteFlexibleWorkspaces:
    "I am looking for remote flexible workspaces for my team",
  HybridSpaceManagementSolution:
    "I am looking for hybrid space management solutions",
  ConnectDistributedTeams:
    "I am looking for ways to connect and engage distributed teams",
  CutRealEstateCosts: "I am looking to cut my real estate costs",
} as const;

export const HouseRules = {
  ShoesOff: "Shoes off",
  MusicNotAllowed: "Music not allowed",
  PetsNotAllowed: "Pets not allowed",
  MusicAllowed: "Music allowed",
  CatsAllowed: "Cats allowed",
  DogsAllowed: "Dogs allowed",
  CatOnPremise: "Cat on premise",
  DogOnPremise: "Dog on premise",
  FoodAllowed: "Food allowed",
} as const;

export const HostPresences = {
  Always: "always",
  Sometimes: "sometimes",
  Never: "never",
};

export const DEFAULT_BOOKING_START_TIME = "9:00AM";

const MIDNIGHT = "12:00AM";

export const Hours = [
  "6:00AM",
  "6:30AM",
  "7:00AM",
  "7:30AM",
  "8:00AM",
  "8:30AM",
  "9:00AM",
  "9:30AM",
  "10:00AM",
  "10:30AM",
  "11:00AM",
  "11:30AM",
  "12:00PM",
  "12:30PM",
  "1:00PM",
  "1:30PM",
  "2:00PM",
  "2:30PM",
  "3:00PM",
  "3:30PM",
  "4:00PM",
  "4:30PM",
  "5:00PM",
  "5:30PM",
  "6:00PM",
  "6:30PM",
  "7:00PM",
  "7:30PM",
  "8:00PM",
  "8:30PM",
  "9:00PM",
  "9:30PM",
  "10:00PM",
  "10:30PM",
  "11:00PM",
  "11:30PM",
  MIDNIGHT,
  "12:30AM",
  "1:00AM",
  "1:30AM",
  "2:00AM",
] as const;

export const HoursPostMidnight = Hours.slice(Hours.indexOf(MIDNIGHT));
export const enum DateFormats {
  EEE_COMMA_D_MMMM = "EEE, d MMMM",
  EEE_COMMA_D_MMM = "EEE, d MMM",
  EEE_D_MMM = "EEE d MMM",
  MMM_D_YYYY = "MMM d, yyyy",
  D_MMM_YYYY = "d MMM yyyy",
  D_MMM_YYYY_WITH_HOUR = "d MMM yyyy, h:mma",
  D_MMM_YYYY_WITH_HOUR_AND_TZ = "d MMM yyyy, h:mma zzz",
  MMMM_DD_YYYY = "MMMM dd, yyyy",
  D_MMMM_YYYY = "d MMMM yyyy",
  DD_MMM_YYYY = "dd MMM, yyyy",
  MM_DD_YYYY = "MM/dd/yyyy",
  HOUR_ONLY = "h:mma",
  HOUR_AND_TZ_ONLY = "h:mma zzz",
  FULL_DAY_NAME = "EEEE",
  MMMM_YYYY = "MMMM yyyy",
  FULL_MONTH_NAME = "MMMM",
  MMM_D = "MMM d",
  MMM_DD = "MMM dd",
  MMMM_DO = "MMMM do",
  MMM_YYYY = "MMM yyyy",
  SHORT_DAY_NAME = "EEE",
  ISO_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX",
  SHORT_DATE = "dd",
}

type InterestsKeys = keyof typeof Interests;
export type InterestsValues = (typeof Interests)[InterestsKeys];

type ObjectivesKeys = keyof typeof Objectives;
export type ObjectivesValues = (typeof Objectives)[ObjectivesKeys];

type WorkStylesKeys = keyof typeof WorkStyles;
export type WorkStylesValues = (typeof WorkStyles)[WorkStylesKeys];

type HostPresencesKeys = keyof typeof HostPresences;
export type HostPresencesValues = (typeof HostPresences)[HostPresencesKeys];

type SpaceTypeKeys = keyof typeof SpaceType;
export type SpaceTypeValues = (typeof SpaceType)[SpaceTypeKeys];

export const objectivesIcons = {
  [Objectives.ChangeOfScenery]: "/icons/objectives/change-scenery.svg",
  [Objectives.IncreaseProductivity]: "/icons/objectives/ob-productivity.svg",
  [Objectives.Networking]: "/icons/objectives/networking.svg",
  [Objectives.WorkWithColleagues]: "/icons/objectives/work-with-colleagues.svg",
};

export const Departments = {
  CustomerExperience: "Customer Experience",
  Data: "Data",
  Sales: "Sales",
  Marketing: "Marketing",
  Executive: "Executive",
  Finance: "Finance",
  People: "People",
  ProfessionalServices: "Professional Services",
  Engineering: "Engineering",
  Operations: "Operations",
  PerformanceTeam: "Performance Team",
  CentralOps: "Central Ops",
  ArtsAndDesign: "Arts and Design",
  BusinessDevelopment: "Business Development",
  CommunityAndSocialServices: "Community and Social Services",
  Consulting: "Consulting",
  Education: "Education",
  Entrepreneurship: "Entrepreneurship",
  HealthcareServices: "Healthcare Services",
  HumanResources: "Human Resources",
  InformationTechnology: "Information Technology",
  Legal: "Legal",
  MediaAndCommunication: "Media and Communication",
  MilitaryAndProtectiveServices: "Military and Protective Services",
  ProductManagement: "Product Management",
  ProgramAndProjectManagement: "Program and Project Management",
  Purchasing: "Purchasing",
  QualityAssurance: "Quality Assurance",
  Research: "Research",
  Support: "Support",
} as const;

export const GENERAL_ERROR_MESSAGE =
  "An error occurred. Please reload the page and try again.";

export const IMAGE_TOO_LARGE_ERROR_MESSAGE =
  "Your photo resolution is too high. Please retry with a smaller photo.";

export const IMAGE_INVALID_FORMAT =
  "Please select a supported file format (JPEG, PNG).";

export const StripeTutorialEmbedLink =
  "https://www.loom.com/embed/d0d7d13daf4c4f27a594827080dc42e4?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true";

export const monthNamesShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
] as const;

export type DirectoryType =
  | "azure scim v2.0"
  | "bamboohr"
  | "breathe hr"
  | "generic scim v1.1"
  | "generic scim v2.0"
  | "gsuite directory"
  | "hibob"
  | "jump cloud scim v2.0"
  | "okta scim v1.1"
  | "okta scim v2.0"
  | "onelogin scim v2.0"
  | "pingfederate scim v2.0"
  | "rippling"
  | "workday";

export interface HrisProviderItem {
  name: DirectoryType;
  label: string;
  icon: string;
}

export const HrisProviders: HrisProviderItem[] = [
  {
    name: "bamboohr",
    label: "BambooHR",
    icon: "/icons/providers/bamboo-logo.png",
  },
  {
    name: "rippling",
    label: "Rippling",
    icon: "/icons/providers/rippling-logo.png",
  },

  {
    name: "workday",
    label: "Workday",
    icon: "/icons/providers/workday-logo.png",
  },

  {
    name: "azure scim v2.0",
    label: "Azure AD SCIM",
    icon: "/icons/providers/azure-logo.png",
  },
  {
    name: "onelogin scim v2.0",
    label: "OneLogin SCIM",
    icon: "/icons/providers/1-login-scim-logo.png",
  },

  {
    name: "breathe hr",
    label: "Breathe HR",
    icon: "/icons/providers/breathe-hr-logo.png",
  },
  {
    name: "gsuite directory",
    label: "Google Workspace",
    icon: "/icons/providers/google-logo.png",
  },
  {
    name: "jump cloud scim v2.0",
    label: "JumpCloud SCIM",
    icon: "/icons/providers/jumpcloud-scim.png",
  },
  {
    name: "okta scim v1.1",
    label: "Okta SCIM 1.1",
    icon: "/icons/providers/okta-logo.png",
  },
  {
    name: "okta scim v2.0",
    label: "Okta SCIM 2.0",
    icon: "/icons/providers/okta-logo.png",
  },
  {
    name: "pingfederate scim v2.0",
    label: "PingFederate SCIM",
    icon: "/icons/providers/pingfederate-scim-logo.png",
  },
  {
    name: "generic scim v1.1",
    label: "SCIM 1.1",
    icon: "/icons/providers/scim-logo.png",
  },
  {
    name: "generic scim v2.0",
    label: "SCIM 2.0",
    icon: "/icons/providers/scim-logo.png",
  },
  // {
  //   name: "adp",
  //   label: "ADP",
  //   icon: "/icons/providers/adp-logo.png",
  // },
  // {
  //   name: "cyberark",
  //   label: "CyberArk SCIM",
  //   icon: "/icons/providers/cyberark-scim-logo.png",
  // },
  // {
  //   name: "gusto",
  //   label: "Gusto",
  //   icon: "/icons/providers/gusto-logo.png",
  // },
  // {
  //   name: "namely",
  //   label: "Namely",
  //   icon: "/icons/providers/namely-logo.png",
  // },
  {
    name: "hibob",
    label: "Hibob",
    icon: "/icons/providers/bob-logo.png",
  },
];

export interface SsoProviderItem {
  label: string;
  icon: string;
}
export type SsoType =
  | "ADFSSAML"
  | "ADPOIDC"
  | "Auth0SAML"
  | "AzureSAML"
  | "CASSAML"
  | "ClassLinkSAML"
  | "CloudflareSAML"
  | "CyberArkSAML"
  | "DuoSAML"
  | "GenericSAML"
  | "GoogleOAuth"
  | "GoogleSAML"
  | "JumpCloudSAML"
  | "KeycloakSAML"
  | "MicrosoftOAuth"
  | "MiniOrangeSAML"
  | "NetIqSAML"
  | "OktaSAML"
  | "OneLoginSAML"
  | "OracleSAML"
  | "PingFederateSAML"
  | "PingOneSAML"
  | "SalesforceSAML"
  | "ShibbolethSAML"
  | "SimpleSamlPhpSAML"
  | "VMwareSAML";

export const SsoProviders: Record<SsoType, SsoProviderItem> = {
  ADFSSAML: {
    label: "AD FS SAML",
    icon: "/icons/providers/bamboo-logo.png",
  },
  ADPOIDC: {
    label: "ADP OIDC",
    icon: "/icons/providers/adp-logo.png",
  },
  Auth0SAML: {
    label: "Auth0 SAML",
    icon: "/icons/providers/autho-logo.png",
  },
  CASSAML: {
    label: "CAS SAML",
    icon: "/icons/providers/cas-logo.png",
  },
  ClassLinkSAML: {
    label: "ClassLink SAML",
    icon: "/icons/providers/classlink-logo.png",
  },
  CloudflareSAML: {
    label: "Cloudflare SAML",
    icon: "/icons/providers/cloudflare-logo.png",
  },
  CyberArkSAML: {
    label: "CyberArk SAML",
    icon: "/icons/providers/cyberark-scim-logo.png",
  },
  DuoSAML: {
    label: "Duo SAML",
    icon: "/icons/providers/duo-logo.png",
  },
  GenericSAML: {
    label: "Generic SAML",
    icon: "/icons/cloud-with-lock.svg",
  },
  AzureSAML: {
    label: "Azure SAML",
    icon: "/icons/providers/azure-logo.png",
  },
  OneLoginSAML: {
    label: "OneLogin SAML",
    icon: "/icons/providers/1-login-scim-logo.png",
  },
  GoogleOAuth: {
    label: "Google OAuth",
    icon: "/icons/providers/google-logo.png",
  },
  GoogleSAML: {
    label: "Google SAML",
    icon: "/icons/providers/google-logo.png",
  },
  JumpCloudSAML: {
    label: "JumpCloud SAML",
    icon: "/icons/providers/jumpcloud-scim.png",
  },
  OktaSAML: {
    label: "Okta SAML",
    icon: "/icons/providers/okta-logo.png",
  },
  PingFederateSAML: {
    label: "PingFederate SAML",
    icon: "/icons/providers/pingfederate-scim-logo.png",
  },
  KeycloakSAML: {
    label: "Keycloak SAML",
    icon: "/icons/providers/keycloak-logo.png",
  },
  MiniOrangeSAML: {
    label: "miniOrange SAML",
    icon: "/icons/providers/miniorange-logo.png",
  },
  MicrosoftOAuth: {
    label: "Microsoft OAuth",
    icon: "/icons/providers/microsoft-logo.png",
  },
  OracleSAML: {
    label: "Oracle SAML",
    icon: "/icons/providers/oracle-logo.png",
  },
  PingOneSAML: {
    label: "PingOne SAML",
    icon: "/icons/providers/pingfederate-scim-logo.png",
  },
  SalesforceSAML: {
    label: "Salesforce SAML",
    icon: "/icons/providers/salesforce-logo.png",
  },
  ShibbolethSAML: {
    label: "Shibboleth SAML",
    icon: "/icons/providers/shibboleth-logo.png",
  },
  VMwareSAML: {
    label: "VMware SAML",
    icon: "/icons/providers/vmware-logo.png",
  },
  SimpleSamlPhpSAML: {
    label: "Simple SAML PHP",
    icon: "/icons/providers/simplesaml-logo.png",
  },
  NetIqSAML: {
    label: "NetIQ SAML",
    icon: "/icons/providers/netiq-logo.png",
  },
};

export const CompanySizes = ["1-10", "11-100", "101-500", "501+"] as const;
export type CompanySizeElement = (typeof CompanySizes)[number];

export const FrequencyPerWeek = ["1-2", "2-3", "3-4"] as const;
export type FrequencyPerWeekElement = (typeof FrequencyPerWeek)[number];

export const CHILD_SPACE_ID_QUERY_PARAM = "space";
export const CHILD_SPACE_TITLE_QUERY_PARAM = "spaceTitle";

export const BOOK_A_DEMO_ANALYTICS_EVENT_NAME = "Request a demo";
export const BOOK_A_DEMO_BUTTON_TEXT = "Get a Demo";

export const enum SearchStripView {
  Collapsed = "collapsed",
  Expanded = "expanded",
}

export type OnboardingVariant = "dialog" | "page";

export const enum OnboardingStatus {
  onboarded = "onboarded",
  partiallyOnboarded = "partiallyOnboarded",
  notOnboarded = "notOnboarded",
}

export const OnboardingStatusTitles = {
  [OnboardingStatus.onboarded]: "ONBOARDED",
  [OnboardingStatus.partiallyOnboarded]: "PARTIALLY ONBOARDED",
  [OnboardingStatus.notOnboarded]: "NOT ONBOARDED",
};

export const HqNeighborhoodColors = {
  vividGreen: "#1AC56A",
  middleBlueGreen: "#89E6CB",
  brightLavender: "#A792F4",
  vividTangerine: "#FFAA91",
  jasmine: "#FFE081",
  lavenderPink: "#F4B0C8",
  deepLilac: "#9564AC",
  paradisePink: "#EE4266",
  capri: "#00C2FF",
  blueCola: "#008CDF",
};

export enum DateAggregationUnit {
  Day = "day",
  Week = "week",
  Month = "month",
  Quarter = "quarter",
}

export const DEFAULT_TIMEZONE = "America/Los_Angeles";

export const DOT = "\u2022";
