import { captureException } from "@sentry/nextjs";
import {
  addHours,
  Day,
  format,
  isSameDay,
  startOfDay,
  subDays,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { stringify } from "query-string";
import slugify from "slugify";
import { AtLeastOne, NoIntersection, Replace } from "src/types/utils";
import { SupportedCurrency } from "src/utils/currency";
import { dateISOToStartOfDay } from "src/utils/dates";
import { SearchableSpace } from "../components/app/search/search-utils";
import { formatDateForFilters } from "../components/common/utils";
import {
  AmenityNames,
  CHILD_SPACE_ID_QUERY_PARAM,
  CHILD_SPACE_TITLE_QUERY_PARAM,
  HostPresencesValues,
  Hours,
  SpaceOnboardingStatus,
  SpaceType,
  SpaceTypeValues,
} from "../constants";
import { User } from "../types/auth-types";
import { IPhoto } from "../types/photo-data-types";
import { IntegrationSource } from "./common";
import { populateHqFloors } from "./company-offices";
import { shouldShowCountryInsteadOfState } from "./locations";
import api from "./root";
import type {
  PopulatedSpaceContainer,
  SpaceContainer,
  SpaceContainerId,
  SpaceContainerType,
  SpaceContainerWithSpaces,
} from "./space-containers";
import { AttendingUser } from "./bookings";

export type ListingPhoto = string;

export enum SpaceBookingType {
  CommonSpace = "CommonSpace",
  MeetingRoom = "MeetingRoom",
  PrivateOffice = "PrivateOffice",
  EventSpace = "EventSpace",
}

export const spaceBookingTypeLabels = {
  [SpaceBookingType.CommonSpace]: "Common space",
  [SpaceBookingType.MeetingRoom]: "Meeting room",
  [SpaceBookingType.PrivateOffice]: "Private office",
  [SpaceBookingType.EventSpace]: "Event space",
} as const satisfies Record<SpaceBookingType, string>;

export const BOOKING_TYPE_SORTING_ORDER = [
  SpaceBookingType.CommonSpace,
  SpaceBookingType.MeetingRoom,
  SpaceBookingType.PrivateOffice,
  SpaceBookingType.EventSpace,
] as const;

export const SPACE_STATUS_SORTING_ORDER = [
  SpaceOnboardingStatus.Active,
  SpaceOnboardingStatus.Unlisted,
  SpaceOnboardingStatus.InReview,
  SpaceOnboardingStatus.Draft,
  SpaceOnboardingStatus.Deleted,
] as const;

export const ALL_BOOKING_TYPES = Object.freeze(Object.values(SpaceBookingType));

export enum UnavailabilityReason {
  SPACE_FULL = "SPACE_FULL",
  WEEKDAY_NOT_AVAILABLE = "WEEKDAY_NOT_AVAILABLE",
  DATE_BLOCKED = "DATE_BLOCKED",
  USER_NOT_ASSIGNED_TO_SPACE = "USER_NOT_ASSIGNED_TO_SPACE",
  ASSIGNED_SPACE_AVAILABLE = "ASSIGNED_SPACE_AVAILABLE",
  SPACE_UNLISTED = "SPACE_UNLISTED",
}

export type AvailabilityWarningReason =
  | UnavailabilityReason.USER_NOT_ASSIGNED_TO_SPACE
  | UnavailabilityReason.ASSIGNED_SPACE_AVAILABLE;

export type ISpaceAvailability = {
  spaceId: SpaceId;
  date: string;
  bookedSeats: number;
  availableSeats: number;
  spaceBookings: ISpaceBooking[];
  unavailabilityReason?: UnavailabilityReason;
  unavailabilityMessage?: string;
  allowHourlyBooking: boolean;
  availabilityHourIntervals?: {
    startHour: (typeof Hours)[number];
    endHour: (typeof Hours)[number];
  }[];
  availabilityWarning?: AvailabilityWarningReason;
};

export type IAvailabilityMap = Map<SpaceId, ISpaceAvailability>;

export interface IBookingApprovalSettings {
  autoApprove: boolean;
  maxAutoApprovedSeatsPerDay: number;
}

export interface ISpaceBooking {
  primaryGuest: User;
  invitedGuests: User[];
  seats: number;
  bookingId: string;
  companyId?: string;
  isHourly: boolean;
  startHour?: string;
  endHour?: string;
  checkedInGuests: AttendingUser[];
}

export interface IWifiDetails {
  networkName: string;
  password: string;
}

export interface ISpaceContact {
  firstName?: string;
  lastName?: string;
  isPrimary?: boolean;
  email: string;
  phone: string;
}

export interface IWorkingHours {
  startHour: (typeof Hours)[number];
  endHour: (typeof Hours)[number];
  day: Day;
}

export interface IRelatedSpace {
  _id: string;
  title: string;
  photos: string[];
  photosData: IPhoto[];
  seats: number;
}

export interface ISpaceReview {
  surveyResponseId: string;
  bookingDate: string;
  satisfactionScore: number;
  freeTextResponse: string;
  user: {
    _id: string;
    firstName: string;
    uploadedProfilePic?: string;
    chosenProfilePic?: string;
    linkedinProfilePic?: string;
    facebookProfilePic?: string;
  };
}

type SpaceCalendarIntegration = {
  calendarId?: string;
  credentials?: string;
};

export type CalendarOption = {
  id: string;
  summary: string;
  primary?: boolean;
  description?: string;
};

export type SpaceIntegrationDetails = {
  integrationSource: IntegrationSource;
  integration: string;
  integrationIdentifier: Record<string, unknown> & {
    uniqueIdentifier: string;
  };
};

export interface SpaceGeo {
  street1: string;
  street2?: string;
  city: string;
  cityLowercase: string;
  postal?: string;
  state?: string;
  stateLowercase?: string;
  country: string;
  countryLowercase: string;
  location: string;
  googleLocation?: string;
  locationOverride?: {
    isActive: boolean;
    location: string;
  };
  neighborhood: string;
  geoLocation: string;
  region: {
    latitude: number;
    longitude: number;
    latitudeDelta: number;
    longitudeDelta: number;
  };
  timeZoneId: string;
  googlePlaceId?: string;
}

export type SpaceBasePopulations = {
  populateHost?: boolean;
};

export interface SpaceBase<TPopulations extends SpaceBasePopulations = {}> {
  status: SpaceOnboardingStatus;
  host?: TPopulations["populateHost"] extends true
    ? Partial<User>
    : TPopulations["populateHost"] extends false
    ? string
    : string | Partial<User>;
  title: string;
  description: string;
  isDemo?: boolean;
  companyId?: string;
  isCompanyOffice?: boolean;
  parkingOnSite?: boolean;
  workingHours: IWorkingHours[];
  photosData: IPhoto[];
  amenities: typeof AmenityNames;
  createdAt: string;
  updatedAt: string;
  rules: string[];
  directions: string;
  wifiDetails?: IWifiDetails;
  contacts: ISpaceContact[];
  currency?: SupportedCurrency;
  integrationDetails?: SpaceIntegrationDetails;
  type: SpaceTypeValues;
}

export const enum SpaceAssignmentType {
  User = "User",
  Department = "Department",
}

export type SpaceAssignment =
  | {
      type: SpaceAssignmentType.User;
      email: string;
      _id?: string;
    }
  | {
      type: SpaceAssignmentType.Department;
      name: string;
    };

export type SpaceId = string & { readonly _opaque: unique symbol };

type MinimalContainer = {
  _id: SpaceContainerId;
  containerType: SpaceContainerType;
  title: string;
};

export type SpacePopulations = {
  populateSpaceContainer?: boolean;
} & SpaceBasePopulations;

export interface Space<TPopulations extends SpacePopulations = {}>
  extends SpaceGeo,
    SpaceBase<TPopulations> {
  _id: SpaceId;
  bookingType: SpaceBookingType;
  seats: number;
  hostPresence: HostPresencesValues;
  hot: boolean;
  minSeatsRequiredToBook?: number;
  hostServiceFeeDecimalFraction?: number;
  thresholdForRejectionInHoursFromBookingDate: number;
  bookingApprovalSettings: IBookingApprovalSettings;
  blockedDays: string[];
  relatedSpaces?: IRelatedSpace[];
  calendarIntegration?: SpaceCalendarIntegration;
  approvedAt?: string;
  satisfactionScore?: number;
  reviewedBookingsCount?: number;
  integrationDetails?: SpaceIntegrationDetails;
  container?: TPopulations["populateSpaceContainer"] extends true
    ? MinimalContainer
    : TPopulations["populateSpaceContainer"] extends false
    ? SpaceContainerId
    : SpaceContainerId | MinimalContainer;
  assignments: SpaceAssignment[];
  prices?: {
    seat?: number | null;
    entireSpace?: number | null;
    entireSpaceHourly?: number | null;
  };
  containerOverrideFields?: {
    contacts?: ISpaceContact[];
  };
  googlePlaceId?: string;
  limits?: {
    maxDaysToBookInAdvance?: number | null;
  };
  bookingSettings?: {
    allowHourlyBooking?: boolean;
  };
}

export interface SpaceWithPrices
  extends Space<{ populateHost: true; populateSpaceContainer: false }> {
  priceInfoForUser: {
    seat: number | null;
    entireSpace: number | null;
    entireSpaceHourly: number | null;
    currency: SupportedCurrency;
  };
}

export async function add(
  options: Pick<Space, "city" | "street1" | "postal" | "state" | "location"> &
    Partial<Space>
) {
  const response = await api.put<Space>(`/spaces`, options);
  return response.data;
}

export async function createMultipleSpaces({
  id,
  options,
}: {
  id: string;
  options: {
    type: SpaceType;
    spacesToCreate: {
      bookingType: SpaceBookingType;
      count: number;
    }[];
  };
}) {
  const response = await api.post<{ spaces: Space[] }>(
    `/spaces/create-multiple-spaces/${id}`,
    options
  );
  return response.data;
}

export async function updateSpaces({ spaces }: { spaces: Partial<Space>[] }) {
  const response = await api.post<{ spaces: Space[] }>(`/spaces/update`, {
    spaces,
  });
  return response.data;
}

export async function submitSpaces(spacesIds: string[]) {
  const response = await api.post(`/spaces/submit`, {
    spacesIds,
  });
  return response.data;
}

export async function toggleSpaceDateBlock({
  id,
  date,
  block,
}: {
  id: string;
  date: string;
  block: boolean;
}) {
  const response = await api.post<void>(`/spaces/toggle-date-block/${id}`, {
    date,
    block,
  });
  return response.data;
}

export async function listMine({
  status,
  includeContainers,
  ids,
}: {
  status?: SpaceOnboardingStatus;
  includeContainers?: boolean;
  ids?: SpaceId[];
} = {}) {
  const response = await api.get<{
    spaces: Replace<
      Space<{ populateSpaceContainer: false; populateHost: false }>,
      { host: string }
    >[];
    containers: SpaceContainer<{
      populateHost: false;
      populateHostOnSpaces: false;
      populateSpaces: false;
    }>[];
  }>("/spaces/my", {
    params: {
      ...(ids ? { ids } : null),
      ...(includeContainers ? { includeContainers } : null),
    },
  });
  if (!status) {
    return response.data;
  }

  return {
    ...response.data,
    spaces: response.data.spaces.filter((space) => space.status === status),
  };
}

export async function deleteSpace(spaceId: SpaceId) {
  const response = await api.delete<{}>(`/spaces/${spaceId}`);
  return response.data;
}

export async function deleteSpaceContainer(containerId: SpaceContainerId) {
  const response = await api.delete<{}>(`/containers/${containerId}`);
  return response.data;
}

export async function changeSpaceListedStatus({
  spaceIds,
  status,
}: {
  spaceIds: SpaceId[];
  status: SpaceOnboardingStatus.Active | SpaceOnboardingStatus.Unlisted;
}) {
  return (
    await api.post<{}>("/spaces/change-listed-status", {
      spaceIds,
      status,
    })
  ).data;
}

export async function uploadPhoto(ids: string[], photo: Partial<IPhoto>, file) {
  const formData = new FormData();
  const { isCoverImage, ...uploadData } = photo;
  formData.append("space-pic", file);
  formData.append("spacesIds", ids.toString());
  Object.entries(uploadData).forEach(([key, value]) => {
    formData.append(key, String(value));
  });

  const response = await api.post<Record<string, { photo: IPhoto }>>(
    "/photos/space",
    formData
  );
  return response.data;
}

export async function deletePhoto(ids: string[], url: string) {
  const response = await api.delete<{}>(`/spaces/photo`, {
    data: {
      photo: url,
      spacesIds: ids,
    },
  });
  return response.data;
}

export async function cloneSpace(
  spaceId: SpaceId,
  bookingType: SpaceBookingType
) {
  const response = await api.post<{}>(`/spaces/clone/${spaceId}`, {
    bookingType,
  });
  return response.data;
}

export async function updatePhotoMetadata(
  spacesIds: string[],
  photo: Partial<IPhoto>
) {
  const response = await api.post<{}>(`/spaces/photo`, {
    ...photo,
    spacesIds,
  });
  return response.data;
}

export async function setPhotoAsCover(spaceId, url: string) {
  const response = await api.post<{}>(`/spaces/updatePrimaryPhoto/${spaceId}`, {
    photo: url,
  });
  return response.data;
}

export async function getAddress({ lat, long }: { lat: number; long: number }) {
  const response = await api.get<google.maps.GeocoderResult>(
    "/spaces/geocode/coords",
    { params: { lat, long } }
  );
  return response.data;
}

export async function getAddressByAddress({ address }: { address: string }) {
  const response = await api.get<google.maps.GeocoderResult>(
    "/spaces/geocode",
    { params: { address } }
  );
  return response.data;
}

export async function ensurePlaceIdLocation(
  params: Pick<
    Space,
    "street1" | "street2" | "city" | "state" | "postal" | "googlePlaceId"
  >
) {
  const response = await api.get<{ space: Partial<Space> }>(
    "/spaces/ensure-location",
    { params }
  );
  return response.data;
}

export const getShortAddress = (
  space: Pick<Space, "country" | "city">,
  stateShortName: string
) => {
  if (space.country && shouldShowCountryInsteadOfState(space.country)) {
    return `${space.city}, ${space.country}`;
  }

  return [space.city, stateShortName].filter(Boolean).join(", ");
};

export const isNew = (
  space: Pick<SpaceBase, "createdAt"> & ({} | Pick<Space, "approvedAt">)
) => {
  try {
    const date = ("approvedAt" in space && space.approvedAt) || space.createdAt;
    return new Date(date) > subDays(new Date(), 30);
  } catch (error) {
    return false;
  }
};

const isInvitedGuest = (u: User, booking: ISpaceBooking) =>
  u && (!booking.primaryGuest || u._id !== booking.primaryGuest._id);

export const getCompanyBookedGuestsFromAvailability = (
  availability: ISpaceAvailability,
  companyId: string
) => {
  return (
    availability?.spaceBookings
      .flatMap((booking) => [
        ...(booking.primaryGuest ? [booking.primaryGuest] : []),
        ...(booking.invitedGuests.filter((u) => isInvitedGuest(u, booking)) ??
          []),
      ])
      .filter((u) => u.company === companyId) ?? []
  );
};

export const getCompanyBookedGuestsWithBookingFromAvailability = (
  availability: ISpaceAvailability,
  companyId: string
) => {
  return (
    availability?.spaceBookings
      .flatMap((booking) => [
        ...(booking.primaryGuest
          ? [{ guest: booking.primaryGuest, booking }]
          : []),
        ...(
          booking.invitedGuests.filter((u) => isInvitedGuest(u, booking)) ?? []
        ).map((guest) => ({ guest, booking })),
      ])
      .filter(
        (guestWithBooking) => guestWithBooking.guest.company === companyId
      ) ?? []
  );
};

export const getRelevantAvailabilityByDate = (
  space: Space,
  availability: ISpaceAvailability[],
  date: Date
) =>
  availability.find(
    (avail) =>
      avail.spaceId === space._id &&
      isSameDay(date, dateISOToStartOfDay(avail.date))
  );

export const isSpaceTooLateToBook = (
  space: Pick<
    Space,
    "timeZoneId" | "thresholdForRejectionInHoursFromBookingDate"
  >,
  date: Date
): boolean => {
  const timeInCurrentTimeZone = utcToZonedTime(
    new Date(),
    space.timeZoneId || "America/Los_Angeles"
  );
  const thresholdTimeForBooking = getThresholdTimeForSpaceBooking(
    date,
    space.thresholdForRejectionInHoursFromBookingDate
  );
  return timeInCurrentTimeZone > thresholdTimeForBooking;
};

export const isSpaceAvailableOnDate = (space: Space, date: Date): boolean => {
  const day = date.getDay();
  return (
    Boolean(space.workingHours?.length) &&
    space.workingHours.findIndex((wh) => wh.day === day) > -1
  );
};

export const isSpaceAvailableOnDates = (
  space: Space,
  dates: Date[]
): boolean => {
  const days = dates.reduce<number[]>((acc, curr) => {
    const day = curr.getDay();
    if (!acc.includes(day)) {
      acc.push(day);
    }
    return acc;
  }, []);
  return days.every(
    (d) => space.workingHours.findIndex((wh) => wh.day === d) > -1
  );
};

export function getThresholdHourForSpaceBooking(
  thresholdForRejectionInHoursFromBookingDate: number
) {
  return thresholdForRejectionInHoursFromBookingDate - 2;
}

export const getThresholdTimeForSpaceBooking = (
  date: Date,
  thresholdForRejectionInHoursFromBookingDate: number
) => {
  return addHours(
    startOfDay(date),
    getThresholdHourForSpaceBooking(thresholdForRejectionInHoursFromBookingDate)
  );
};

export const getThresholdTimeForSpaceBookingAsString = (
  thresholdForRejectionInHoursFromBookingDate: number
) => {
  const dateToFormat = getThresholdTimeForSpaceBooking(
    new Date(),
    thresholdForRejectionInHoursFromBookingDate
  );
  return format(dateToFormat, "p");
};

export const getTooLateToBookString = (
  thresholdForRejectionInHoursFromBookingDate: number
) =>
  `Reservations must be completed by ${getThresholdTimeForSpaceBookingAsString(
    thresholdForRejectionInHoursFromBookingDate
  )} on the day ${
    getThresholdHourForSpaceBooking(
      thresholdForRejectionInHoursFromBookingDate
    ) < 0
      ? "before"
      : "of"
  } the booking`;

export const generateSpaceLink = ({
  space,
  showHqLobby,
  date,
  seats,
  extraQueryParams,
}: {
  space: SearchableSpace;
  showHqLobby: boolean;
  date?: Date | string;
  seats?: number;
  extraQueryParams?: Record<string, string | string[]>;
}) => {
  const url = space.isCompanyOffice
    ? isSpaceContainer(space)
      ? `/hq/${space._id}`
      : `/hq/${
          (space.container &&
            (typeof space.container === "string"
              ? space.container
              : space.container._id)) ||
          space._id
        }`
    : `/spaces/${space._id}/${slugify(space.title, {
        lower: true,
        strict: true,
      })}`;

  const searchParams = new URLSearchParams();

  if (date) {
    searchParams.set(
      "d",
      formatDateForFilters(
        typeof date === "string" ? dateISOToStartOfDay(date) : date
      )
    );
  }

  if (seats) {
    searchParams.set("q", String(seats));
  }

  if (space.isCompanyOffice && !showHqLobby && !isSpaceContainer(space)) {
    searchParams.set(CHILD_SPACE_ID_QUERY_PARAM, space._id);
    searchParams.set(
      CHILD_SPACE_TITLE_QUERY_PARAM,
      slugify(space.title, {
        lower: true,
        strict: true,
      })
    );
  }

  Object.entries(extraQueryParams || {}).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => searchParams.append(key, v));
    } else {
      searchParams.set(key, value);
    }
  });
  const queryString = searchParams.toString();
  return queryString ? `${url}?${queryString}` : url;
};

export const isSpaceBookable = (space: Space) =>
  !!(space.seats && space.workingHours?.length);

export interface ExploreOptions {
  date?: string;
  amenities?: string[];
  interests?: string[];
  seats?: number;
  spaceTypes?: SpaceTypeValues[];
  spaceBookingTypes?: SpaceBookingType[];
  geoLocation?: string;
  dogFriendlyOnly?: boolean;
  autoApprovalOnly?: boolean;
  geoBox?: {
    neLat: number;
    neLng: number;
    swLat: number;
    swLng: number;
  };
  minPrice?: number;
  maxPrice?: number;
  isDemo?: boolean;
  withAvailability?: boolean;
  spaceIds?: SpaceId[];
  currency?: SupportedCurrency;
}

export async function exploreTopSpaces() {
  const response = await api.get<{
    spaces: Space[];
  }>("/spaces/explore/top-spaces");

  return response.data;
}

export async function explore(options: ExploreOptions, signal?: AbortSignal) {
  const query = Object.entries(options).reduce((acc, [key, value]) => {
    if (value !== null && value instanceof Object) {
      acc[key] = JSON.stringify(value);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});

  const response = await api.get<{
    containers: SpaceContainer[];
    spaces: SpaceWithPrices[];
    availability: ISpaceAvailability[];
  }>(`/spaces/explore/v2?${stringify(query)}`, { signal });

  const data = response.data;

  data.availability.forEach((availability) => {
    availability.spaceBookings =
      removeIncognitoReplacementUsersFromSpaceBookings(
        availability.spaceBookings
      );
  });
  return {
    ...data,
    containers: data.containers
      ?.filter(
        // currently only support HQ containers
        (c) => !!c.spaces.length && c.isCompanyOffice
      )
      .map(populateHqFloors),
  };
}

export type SpaceContainerWithSpacesExplore = Replace<
  SpaceContainerWithSpaces,
  {
    spaces: (SpaceContainerWithSpaces["spaces"][number] & {
      assignedToUser?: boolean;
    })[];
  }
>;

export async function exploreSpace<
  TIncludeContainers extends boolean = false
>(options: {
  id: SpaceId | SpaceContainerId;
  currency?: SupportedCurrency;
  dates?: string[];
  withAvailability?: boolean;
  includeContainers?: TIncludeContainers;
  assigneeId?: string;
}): Promise<
  {
    availability: ISpaceAvailability[];
  } & (TIncludeContainers extends true
    ? AtLeastOne<{
        container: PopulatedSpaceContainer<SpaceContainerWithSpacesExplore>;
        space: SpaceWithPrices;
      }>
    : {
        space: SpaceWithPrices;
      })
> {
  const {
    id: spaceId,
    dates,
    currency,
    includeContainers,
    withAvailability = true,
    assigneeId,
  } = options;

  const response = await api.get<
    {
      availability: ISpaceAvailability[];
    } & (TIncludeContainers extends true
      ? AtLeastOne<{
          container: SpaceContainerWithSpacesExplore;
          space: SpaceWithPrices;
        }>
      : {
          space: SpaceWithPrices;
        })
  >(`/spaces/explore/${spaceId}`, {
    params: {
      withAvailability,
      includeContainers,
      currency,
      ...(dates ? { dates } : undefined),
      assigneeId,
    },
  });

  const data = response.data;
  data.availability.forEach((availability) => {
    availability.spaceBookings =
      removeIncognitoReplacementUsersFromSpaceBookings(
        availability.spaceBookings
      );
  });

  if ("container" in data) {
    return {
      ...data,
      container: data.container && populateHqFloors(data.container),
    } as Replace<
      typeof data,
      { container: PopulatedSpaceContainer<SpaceContainerWithSpacesExplore> }
    >;
  }
  return data as TIncludeContainers extends true
    ? never
    : {
        availability: ISpaceAvailability[];
        space: SpaceWithPrices;
      };
}

export type SpaceUserAssignee = Pick<
  User,
  | "_id"
  | "firstName"
  | "lastName"
  | "email"
  | "uploadedProfilePic"
  | "chosenProfilePic"
  | "facebookProfilePic"
  | "linkedinProfilePic"
> & { department: string };

export const getSpaceUserAssignees = async (options: {
  _id: string;
  type: "space" | "container";
}) => {
  return (
    await api.get<{ assignees: SpaceUserAssignee[] }>(
      `/spaces/explore/${options._id}/assignees`,
      { params: { type: options.type } }
    )
  )?.data?.assignees;
};

export async function getSpaceAvailabilityForDates(
  options: { id: SpaceId; assigneeId?: string } & (
    | { dates: string[] }
    | { startDate: string; endDate: string }
  )
) {
  const response = await api.get<{ availability: ISpaceAvailability[] }>(
    `/spaces/explore/availability/${options.id}`,
    {
      params: {
        assigneeId: options.assigneeId,
        ...("dates" in options
          ? { dates: options.dates }
          : { startDate: options.startDate, endDate: options.endDate }),
      },
    }
  );
  const { availability } = response.data;
  availability.forEach((av) => {
    av.spaceBookings = removeIncognitoReplacementUsersFromSpaceBookings(
      av.spaceBookings
    );
  });
  return availability;
}

export async function getSpaceAvailabilityForDate(options: {
  id: SpaceId;
  date: string;
}): Promise<ISpaceAvailability> {
  const response = await api.get<{ availability: ISpaceAvailability[] }>(
    `/spaces/explore/availability/${options.id}?date=${options.date}`
  );
  const availabilities = response.data.availability;
  if (availabilities.length > 1) {
    captureException(
      `getSpaceAvailabilityForDate returned more than one result for space ID: ${options.id}, date: ${options.date}`
    );
  }
  const availability = availabilities.find((avail) =>
    isSameDay(
      dateISOToStartOfDay(avail.date),
      dateISOToStartOfDay(options.date)
    )
  );
  if (availability) {
    availability.spaceBookings =
      removeIncognitoReplacementUsersFromSpaceBookings(
        availability.spaceBookings
      );
  }
  return availability;
}

export async function getSpaces(options: {
  approvedAtStartDate?: string;
  approvedAtEndDate?: string;
  geoLocationIds?: string[];
  limit?: number;
  ids?: SpaceId[];
  statuses?: SpaceOnboardingStatus[];
  sortByPopularity?: boolean;
  excludeCompanyOffices?: boolean;
  currency?: SupportedCurrency;
}) {
  const response = await api.get<{
    spaces: SpaceWithPrices[];
  }>("/spaces", {
    params: options,
  });

  return response.data.spaces;
}

export async function searchSpacesByTitle(options: { term: string }) {
  const response = await api.get<{
    spaces: Space[];
    containers: SpaceContainer[];
  }>("/spaces/explore/v2/search", {
    params: options,
  });
  return {
    ...response.data,
    containers: response.data.containers?.map(populateHqFloors),
  };
}

export async function createCalendarConnectionAuthUrl(options: {
  spaceId: SpaceId;
  redirectUri: string;
}) {
  const response = await api.post<{ authUrl: string }>(
    `/spaces/calendar/connect/${options.spaceId}`,
    options
  );
  return response.data;
}

export async function getCalendarListForSpace(options: { spaceId: SpaceId }) {
  const response = await api.get<{ calendars: CalendarOption[] }>(
    `/spaces/calendars/${options.spaceId}`
  );
  return response.data.calendars;
}

export async function selectCalendarIdForSpace(options: {
  spaceId: SpaceId;
  calendarId: string;
}) {
  await api.post(`/spaces/calendar/select/${options.spaceId}`, options);
}

export async function disconnectCalendar(options: { spaceId: SpaceId }) {
  await api.delete<void>(`/spaces/calendar/${options.spaceId}`);
}

export function getPrimaryContact(
  space: Space,
  host?: Pick<User, "firstName" | "lastName" | "email" | "phone">
): ISpaceContact | null {
  host = host ?? (space.host as User);
  return (
    space.contacts?.find((c) => c.isPrimary) ??
    (space.isCompanyOffice
      ? null
      : host && {
          email: host.email,
          phone: host.phone,
          firstName: host.firstName,
          lastName: host.lastName,
        })
  );
}

const removeIncognitoReplacementUsersFromSpaceBookings = (
  spaceBookings: ISpaceBooking[]
) => {
  return spaceBookings.map((sb) => {
    return {
      ...sb,
      primaryGuest: sb.primaryGuest.isIncognitoReplacement
        ? null
        : sb.primaryGuest,
    };
  });
};

export async function getMinMaxSpacePrices(options: {
  currency: SupportedCurrency;
}) {
  const response = await api.get<{
    entireSpaceMaxPrice: number;
    entireSpaceMinPrice: number;
    seatPriceMax: number;
    seatPriceMin: number;
    currency: SupportedCurrency;
  }>("/spaces/explore/prices", { params: options });
  return response.data;
}

export async function getSpacePublicReviews(id: SpaceId) {
  const response = await api.get<{ publicReviews: ISpaceReview[] }>(
    `/spaces/explore/${id}/public-reviews`
  );

  return response.data.publicReviews;
}

export const connectOfficeRndResource = async ({
  id,
  resourceIdentifier,
}: {
  id: SpaceId;
  resourceIdentifier: Record<string, unknown>;
}) => {
  const { data } = await api.post<{ space: Space }>(
    `/spaces/${id}/integration`,
    { identifier: resourceIdentifier, source: IntegrationSource.OfficeRnd }
  );
  return data;
};
export const connectNexudusResource = async ({
  id,
  resourceIdentifier,
  bookingType,
}: {
  id: SpaceId;
  resourceIdentifier: Record<string, unknown>;
  bookingType: SpaceBookingType;
}) => {
  const { data } = await api.post<{ space: Space }>(
    `/spaces/${id}/integration`,
    {
      source: IntegrationSource.Nexudus,
      identifier: {
        ...resourceIdentifier,
        bookingType,
      },
    }
  );
  return data;
};

export const isSpaceContainer = <
  TSpace extends Partial<NoIntersection<Space, { spaces: any }>>,
  TCandidate extends TSpace | Partial<SpaceContainer>
>(
  candidate: TCandidate
): candidate is Exclude<TCandidate, TSpace> =>
  !!(candidate && "spaces" in candidate && candidate.spaces);

export const isCompanyOffice = <TCandidate extends Partial<SpaceBase>>(
  candidate: TCandidate
): candidate is TCandidate &
  Required<Pick<TCandidate, "companyId" | "isCompanyOffice">> =>
  !!(candidate.companyId && candidate.isCompanyOffice);

export const disconnectIntegration = async ({
  id,
}: {
  id: SpaceId;
  resourceIdentifier: Record<string, unknown>;
}) => {
  const { data } = await api.delete<{ space: Space }>(
    `/spaces/${id}/integration`
  );
  return data;
};
